import React from "react";
import { Container } from "@mui/system";
import {
  IImageProps,
  Image,
  ImageFit,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";

import logo from "./logo.png";

const imageProps: IImageProps = {
  src: logo,
  imageFit: ImageFit.contain,
  width: 180,
};

const Header: React.FC = () => {
  const theme = useTheme();

  return (
    <Stack
      styles={{
        root: {
          padding: theme.spacing.l2,
          backgroundColor: theme.palette.neutralLighterAlt,
        },
      }}
    >
      <Container maxWidth="xl">
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Image alt="Ministry of Natural Resources" {...imageProps} />

          <Stack verticalAlign="center">
            <Text variant="xxLarge">Ministry of Natural Resources</Text>
            <Text variant="xLarge">Petroleum Management Programme</Text>
            <Container disableGutters maxWidth="sm">
              <Stack styles={{ root: { paddingTop: theme.spacing.s2 } }}>
                <Text variant="mediumPlus">
                  Guyana Competitive Licensing Round 2022 Sharing Portal
                </Text>
              </Stack>
            </Container>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Header;
