import React from "react";
import { Container } from "@mui/system";
import { Link, Stack, Text, useTheme } from "@fluentui/react";

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <Stack
      styles={{
        root: {
          padding: theme.spacing.l2,
          backgroundColor: theme.palette.neutralLighterAlt,
        },
      }}
    >
      <Container maxWidth="xl" sx={{ textAlign: "right" }}>
        <Text>
          <Link href="https://petroleum.gov.gy/" target="_blank">
            ©2022 Petroleum Management Programme
          </Link>
        </Text>
      </Container>
    </Stack>
  );
};

export default Footer;
