import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import {
  DocumentCard,
  DocumentCardLogo,
  DocumentCardTitle,
  PrimaryButton,
  Stack,
  useTheme,
} from "@fluentui/react";
import partition from "lodash.partition";
import prettyBytes from "pretty-bytes";

type TNode = {
  name: string;
  path: string;
  size: number;
  type: string;
  extension: string;
  children: TNode[];
};

const Content: React.FC = () => {
  const [rootNode, setRootNode] = useState<TNode>();
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      const response = await fetch("/documents.json");
      const tree = await response.json();
      setRootNode(tree);
    })();
  }, []);

  if (rootNode === undefined) return null;

  return (
    <Stack
      grow
      styles={{
        root: {
          padding: theme.spacing.l2,
        },
      }}
    >
      <Container maxWidth="xl">
        <RootNode node={rootNode} />
      </Container>
    </Stack>
  );
};

type TNodeProps = {
  node: TNode;
};

const RootNode: React.FC<TNodeProps> = ({ node }) => {
  const input = useRef<HTMLAnchorElement>(null);
  const theme = useTheme();

  const [directories] = useMemo(
    () => partition(node.children, (node) => node.type === "directory"),
    [node]
  );

  return (
    <React.Fragment>
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginBottom: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12" style={{ textAlign: "right" }}>
            <PrimaryButton onClick={() => input.current?.click()}>
              Download all
            </PrimaryButton>
            <a
              aria-label={node.name}
              ref={input}
              href={`/Licensing Round 2022.zip`}
              download
              style={{ display: "none" }}
            >
              Download {node.name}
            </a>
          </div>
        </div>
      </Stack>

      <Stack className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          {directories.map((directory) => (
            <div
              key={directory.name}
              className="ms-Grid-col ms-sm12 ms-md6 ms-lg3"
            >
              <Node node={directory} />
            </div>
          ))}
        </div>
      </Stack>
    </React.Fragment>
  );
};

const Node: React.FC<TNodeProps> = ({ node }) => {
  const input = useRef<HTMLAnchorElement>(null);
  const theme = useTheme();

  return (
    <Stack
      styles={{
        root: { marginBottom: theme.spacing.m, cursor: "pointer" },
      }}
      onClick={() => input.current?.click()}
    >
      <DocumentCard aria-label={node.name}>
        <DocumentCardLogo logoIcon="Archive" />
        <DocumentCardTitle title={node.name} />
        <DocumentCardTitle
          showAsSecondaryTitle
          title={prettyBytes(node.size)}
        />
      </DocumentCard>
      <a
        aria-label={node.name}
        ref={input}
        href={`/documents/${node.name}.zip`}
        download
        style={{ display: "none" }}
      >
        Download {node.name}
      </a>
    </Stack>
  );
};

export default Content;
