import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@fluentui/react";

import App from "./App";
import theme from "./theme";

const Root: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Root;
