import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import { mergeStyles } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import reportWebVitals from "./reportWebVitals";
import "office-ui-fabric-core/dist/css/fabric.css";

initializeIcons();

// Inject some global styles
mergeStyles({
  ":global(body,html,#root, #root > div)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
