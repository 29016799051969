import React from "react";
import { Route, Routes } from "react-router-dom";
import Content from "./components/Content";
import Main from "./components/Main";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/5cb24b87-96ac-40a1-af90-a8ffe8df0b30" element={<Main />}>
        <Route path="" element={<Content />} />
      </Route>
    </Routes>
  );
};

export default App;
