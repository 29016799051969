import React from "react";
import { Outlet } from "react-router-dom";
import { Stack } from "@fluentui/react";

import Header from "./Header";
import Footer from "./Footer";

const Main: React.FC = () => {
  return (
    <Stack verticalFill>
      <Header />
      <Outlet />
      <Footer />
    </Stack>
  );
};

export default Main;
